/** @type {HTMLTemplateElement} */
const feedTemplate = document.querySelector(`body > aside > details > nav > ul > template:last-of-type`);

class FeedElement extends HTMLElement {
	constructor() {
		super();
		this.attachShadow({mode: `open`})
			.appendChild(feedTemplate.content.cloneNode(true));
	}

	/**
	 * @param logo {URL}
	 */
	set logo(logo) {
		const logoElement = this.shadowRoot.querySelector(`img`);
		logoElement.src = logo.href;
	}

	/**
	 * @param name {string}
	 */
	set name(name) {
		const nameElement = this.shadowRoot.querySelector(`span:first-of-type`);
		nameElement.appendChild(document.createTextNode(name));
	}

	/**
	 * @param unreadCount {number}
	 */
	set unreadCount(unreadCount) {
		const unreadCountElement = this.shadowRoot.querySelector(`span:last-of-type`);
		unreadCountElement.textContent = unreadCount.toString(10);
		this.shadowRoot.host.classList.toggle(`unread`, unreadCount > 0);
	}
}

customElements.define(`recess-feed`, FeedElement);
